import React from 'react';
import { config, useSpring } from 'react-spring';
import Layout from '../components/layout';
import { AnimatedBox } from '../elements';
import SEO from '../components/SEO';

const About = () => {
    const pageAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0 },
        to: { opacity: 1 },
    });

    return (
        <Layout>
            <SEO
                title="About | Kellie"
                desc="About Kellie. Kellie is a professional actress, working in Film, Television, Theatre and Radio. Kellie was born in Croydon and lives in SE London. She started her career via the National Youth Theatre and the BRIT school."
            />
            <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
                <h1>About Kellie</h1>
                <p>
                    Kellie was born in Croydon and lives in SE London. She started her career via the National Youth
                    Theatre and the BRIT school.
                </p>
                <p>
                    She can currently be seen playing 'Kirsty', in the award-winning comedy series{' '}
                    (1 - 3) <em>In The Long Run</em> (Sky One / Now TV / Starz) created by Idris Elba.
                </p>
                <p>
                    Recent TV work includes <em>Meet The Richardsons</em> (ITV), <em>Death In Paradise</em> (BBC), <em>Keep Calm And Carry On</em> (Amazon Prime), <em>Biff And Chip</em> (BBC S1&2), <em>Call The Midwife</em> (Netflix / BBC), <em>True Horror</em> (Channel 4),
                    and the BAFTA-winning series <em>Joe All Alone</em> - where she played series regular 'Stacey'.
                </p>
                <p>
                    Kellie is probably best known for playing 'Carly Wicks' in <em>Eastenders</em>, clocking up over 200
                    episodes between 2006-2012.
                </p>
                <p>
                    Other TV work includes <em>The Office</em> Christmas Specials, 3-part BBC 4 drama:{' '}
                    <em>Twenty Thousand Streets Under The Sky</em> and <em>The Marriage of Reason and Squalor</em> (Sky
                    Arts).
                </p>
                <p>
                    Her film work includes <em>Bull</em> (Signature), <em>Benediction</em> (Emu), <em>Baby Boy</em> (Film 4/House 38), <em>King of Thieves</em> (Studio Canal), <em>Wimbledon</em> (Working Title),{' '}
                    <em>Venus</em> (MiraMax) and <em>Heidi</em> (Piccadilly Pictures).
                </p>
                <p>
                    Kellie plays the central role of 'Rachel' in short film <em>Who's The Daddy</em> which was selected
                    for Best World Short at the recent Galway Film Festival.
                </p>
                <p>
                    For theatre Kellie has worked for The RSC, National Theatre, Theatre Royal Haymarket, Royal Court,
                    The Park Theatre, London, Soho Theatre, ATG, Lyric Hammersmith. She played the role of 'Pauline Clench' in the International
                    tour / West End of the National Theatre's hugely successful comedy <em>One Man Two Guvnors </em>
                    directed by Nick Hytner.
                </p>
                <p>She is an ambassador for the charities Anthony Nolan and Mencap.</p>
            </AnimatedBox>
        </Layout>
    );
};

export default About;
